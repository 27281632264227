import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import MetaData from '../components/meta/MetaData'
import LinksList from '../components/LinksList'

const LinkedPage = ({ location }) => (
  <>
    <MetaData location={location} type="website" title={`Link Archive`} keywords={[`Michael Goehle`]} description={`relevant links`} />
    <Layout>
      <section id="index" className={`site-section hidden`}>
        <h1 className={`h3 mb-2 text-capitalize`}>Link List</h1>
        <p className={`lead`}>Old Links</p>
        <nav className={`site-nav`}>
          <LinksList />
        </nav>
      </section>
    </Layout>
  </>
)

LinkedPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default LinkedPage
